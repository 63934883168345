export class Accordion {
    constructor()
    {
        this.init()
    }

    init()
    {
        const details = [...document.querySelectorAll('details')];
        document.addEventListener('click', function (e) {
            if (!details.some(f => f.contains(e.target))) {
                details.forEach(f => f.removeAttribute('open'));
            } else {
                details.forEach(f => !f.contains(e.target) ? f.removeAttribute('open') : '');
            }
        })

    }

}
